<template>
  <v-card elevation="0">
    <v-card-text>
      <loader></loader>
      <v-tabs>
        <v-tab>Info</v-tab>
        <v-tab-item>
          <information-section :book="book"></information-section>
        </v-tab-item>
        <v-tab>{{ $t("component.bookForm.sections.authors") }}</v-tab>
        <v-tab-item>
          <author-section :authors="book.authors"></author-section>
        </v-tab-item>
      </v-tabs>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click="save()" :disabled="!isFormValid"
        >Save</v-btn
      >
      <v-btn color="success" @click="log()">Log</v-btn>
      <!-- </v-col>
      </v-row> -->
    </v-card-actions>
  </v-card>
</template>
<script>
import "dropzone/dist/dropzone.css";
import dialogMixin from "../../mixins/dialog";
import * as types from "../../store/mutation-types";
import InformationSection from "./BookFormInformationSection.vue";
import AuthorSection from "./BookFormAuthorSection.vue";
import Loader from "../Layout/Loader.vue";
export default {
  props: ["parameter"],
  mixins: [dialogMixin],
  components: { InformationSection, AuthorSection, Loader },
  data() {
    return {
      isImportedDataLoading: false,
      form: null,
      menu: null,
      isEdit: false,
      fileExtensions: ["pdf", "mobi", "epub"],
      book: {
        publishingDate: null, //this.$moment.utc().format(),
        files: {},
        title: null,
        authors: [],
        exerpt: null,
        themes: [],
        keywords: [],
        isbn: null,
      },
    };
  },
  computed: {
    publishingDate: {
      get() {
        var d = this.parseDate(this.book.publishingDate);
        return d;
      },
      set(d) {
        this.book.publishingDate = this.$moment.utc(d).format();
      },
    },
    hasFiles() {
      var hasFiles = false;
      for (var ext in this.book.files) {
        if (this.book.files[ext] != null) {
          hasFiles = true;
          break;
        }
      }
      return hasFiles;
    },
    files() {
      return this.book.files || {};
    },
    isFormValid() {
      var needFiles =
        this.isEdit ||
        (this.book.title != null &&
          this.book.title.length > 3 &&
          this.hasFiles);
      return this.book.title != null && needFiles;
    },
  },
  watch: {
    // files: {
    //   deep: true,
    //   handler(f) {
    //     if (
    //       Object.keys(f).length != 0 &&
    //       (this.book.name == null || this.book.title.length == 0)
    //     ) {
    //       /**
    //        * @type {String} first
    //        */
    //       var first = null;
    //       for (var ext of this.fileExtensions) {
    //         if (f[ext] != null) {
    //           first = f[ext].name;
    //           break;
    //         }
    //       }
    //       var split = first.split(".");
    //       split.pop();
    //       first = split.join(" ");
    //       first = first.replace(/[_]+/g, " ");
    //       first = first.replace(/\b\w/g, (l) => l.toUpperCase());
    //       first = first.replace(/\( PDFDrive \)/g, "");
    //       this.book.title = first.trim();
    //     }
    //   },
    // },
  },
  methods: {
    parseDate(date) {
      if (!date) {
        return null; //this.$moment.utc().format("YYYY MMMM");
      }

      var f = this.$moment.utc(date).format("YYYY MMM");

      return f;
    },
    save() {
      var data = { ...this.book };
      if (data.themes)
        data.themes = data.themes.map((x) => {
          if (typeof x == "string") {
            return x;
          } else {
            return x.name;
          }
        });

      if (data.keywords)
        data.keywords = data.keywords.map((x) => {
          if (typeof x == "string") {
            return x;
          } else {
            return x.name;
          }
        });

      this.$store
        .dispatch("library/upsert", {
          ...data,
          bookId: this.book.publicId,
          //          publishingDate: this.book.publishingDate,
          //files: this.files,
        })
        .then(() => {
          this.close();
        });
    },
    log() {
      console.log(this.book);
    },
  },
  created() {
    this.$store.dispatch("author/loadAll", {});
    this.$store.dispatch("library/loadThemes", {});
    this.$store.dispatch("utils/loadKeywords", {});
    if (this.parameter && this.parameter.book) {
      this.isEdit = true;

      this.book = { ...this.parameter.book, files: {} };
      this.$store.commit("notification/" + types.UPDATE_DIALOG, {
        id: this.config.id,
        title: this.book.title,
      });
      // TODO: Set files in the form for book that being edited
      console.warn("NYI: Set files in the form for book that being edited");
      // TODO: Modal to prevent quiting this page with change not being saved
      console.warn(
        "NYI: Modal to prevent quiting this page with change not being saved"
      );
    }
  },
};
</script>
