<template>
  <sheet class="mt-6 pt-6">
    <v-form v-model="form">
      <v-row>
        <v-col sm="12">
          <v-text-field
            v-model="book.title"
            :label="$t('component.bookForm.fields.title.label')"
            required
            dense
            hide-details
            :rules="[
              (v) =>
                !!v || $t('component.bookForm.fields.title.errors.required'),
            ]"
          >
            <template v-slot:prepend>
              <v-menu right bottom offset-x offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="searchMetadata"
                    v-on="on"
                    v-bind="attrs"
                    :disabled="!book.title || !book.title.trim()"
                  >
                    <v-icon>mdi-book-search-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <template v-for="(item, index) in bookDataSearch">
                    <v-list-item
                      :key="item.id"
                      three-line
                      @click="fillBookInformations(item)"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          >{{ item.title }} ({{
                            item.publishedDate
                          }})</v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{ item.authors }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle>
                          {{ item.description }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider
                      v-if="index < bookDataSearch.length - 1"
                      :key="index"
                    ></v-divider>
                  </template>
                </v-list>
              </v-menu>
            </template>
          </v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field
            v-model="book.isbn"
            dense
            hide-details
            :label="$t('component.bookForm.fields.isbn.title')"
            :hint="$t('component.bookForm.fields.isbn.hint')"
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field
            v-model="book.edition"
            :label="$t('component.bookForm.fields.edition.title')"
            type="number"
            dense
            min="0"
            hide-details
            :rules="[
              (v) =>
                !!!v ||
                !Number.isNaN(v) ||
                $t('component.bookForm.fields.edition.errors.notANumber'),
              (v) =>
                !!!v ||
                Number(v) > 0 ||
                $t('component.bookForm.fields.edition.errors.underOne'),
            ]"
          ></v-text-field>
        </v-col>

        <v-col sm="12">
          <v-textarea
            v-model="book.exerpt"
            :label="$t('component.bookForm.fields.exerpt.title')"
            validate-on-blur
            dense
            rows="3"
            :rules="[
              (v) =>
                v == null ||
                v.length == 0 ||
                v.length > 3 ||
                $t('component.bookForm.fields.exerpt.errors.length'),
            ]"
            hide-details
          ></v-textarea>
        </v-col>
        <v-col md="5">
          <!-- <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="book.publishingDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="publishingDate"
                  :label="$t('component.bookForm.fields.publishingYear.title')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="book.publishingDate"
                type="month"
              ></v-date-picker>
            </v-menu> -->
        </v-col>
        <v-checkbox
          v-if="isEdit"
          v-model="book.isActive"
          :label="$t('component.bookForm.fields.isActive.title')"
        ></v-checkbox>
        <v-col cols="12">
          <v-combobox
            v-model="book.themes"
            :label="$t('component.bookForm.fields.themes.title')"
            :items="themes"
            item-value="id"
            item-text="name"
            :delimiters="[';']"
            multiple
            chips
            dense
            deletable-chips
            small-chips
          ></v-combobox>
        </v-col>
        <v-col cols="12">
          <v-combobox
            v-model="book.keywords"
            :label="$t('component.bookForm.fields.tags.title')"
            :items="keywords"
            multiple
            :delimiters="[';']"
            chips
            dense
            deletable-chips
            small-chips
          ></v-combobox>
        </v-col>
        <v-col md="12">
          <v-card outlined
            ><v-card-text>
              <v-row justify="center" align="center">
                <v-col md="12"
                  ><p class="display-1 text-center">
                    {{ $t("component.bookForm.fields.files.title") }}
                  </p></v-col
                >
                <v-col md="4" v-for="t in fileExtensions" :key="t">
                  <v-file-input
                    :ref="t"
                    v-model="book.files[t]"
                    :accept="`.${t}`"
                    :label="t.toUpperCase()"
                    :clearable="true"
                    truncate-length="50"
                    outlined
                    counter
                    prepend-icon=""
                    :rules="[
                      () =>
                        hasFiles ||
                        $t('component.bookForm.fields.files.errors.length'),
                    ]"
                    validate-on-blur
                  >
                    <!-- <template v-slot:selection="{ index, text }">
                      <v-chip
                        v-if="index < 2"
                        color="primary accent-4"
                        dark
                        label
                        close
                        @click:close="removeBook(t)"
                      >
                        {{ text }}
                      </v-chip>
                    </template> -->
                  </v-file-input>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </sheet>
</template>
<script>
import Sheet from "../Shared/Sheet.vue";
import * as types from "../../store/mutation-types";
export default {
  props: { book: { type: Object, required: true } },
  components: { Sheet },
  data() {
    return {
      form: null,
      isEdit: false,
      fileExtensions: ["pdf", "mobi", "epub"],
      menu: null,
      bookDataSearch: [],
    };
  },
  computed: {
    authorList() {
      return this.$store.getters["author/authors"];
    },
    bookTitle() {
      return this.book.title;
    },
    keywords() {
      return this.$store.getters["utils/keywords"];
    },
    files() {
      return this.book.files || {};
    },
    hasFiles() {
      return this.hasFilesFn(this.book.files);
    },
    publishingDate: {
      get() {
        var d = this.parseDate(this.book.publishingDate);
        return d;
      },
      set(d) {
        this.book.publishingDate = this.$moment.utc(d).format();
      },
    },
    themes() {
      return this.$store.getters["library/themes"];
    },
  },
  watch: {
    bookTitle(val) {
      this.book.title = val.trim();
    },
    files: {
      deep: true,
      handler(f) {
        if (
          /*Object.keys(f).length != 0*/ (this.book.title == null ||
            this.book.title.trim().length == 0) &&
          this.hasFiles
        ) {
          /**
           * @type {String} first
           */
          var first = null;

          for (var ext of this.fileExtensions) {
            if (f[ext] != null) {
              first = f[ext].name;
              break;
            }
          }
          var split = first.split(".");
          split.pop();
          first = split.join(" ");
          first = first.replace(/[_]+/g, " ");
          first = first.replace(/\b\w/g, (l) => l.toUpperCase());
          first = first.replace(/\( PDFDrive \)/g, "");
          this.book.title = first.trim();
        }
      },
    },
  },
  methods: {
    fillBookInformations(item) {
      var itemAuthors = item.authors
        .map((a) => ({ name: a }))
        .map((a) => {
          let temp = this.authorList.find(
            (x) => x.name.toLowerCase().trim() == a.name.toLowerCase().trim()
          );
          if (temp == null) {
            return a;
          }
          return temp;
        });

      var authors = itemAuthors
        .concat(this.book.authors)
        .filter(
          (author, index, self) =>
            self.findIndex((x) => x.name === author.name) === index
        );
      this.book.authors = authors;
      this.book.isbn = this.book.isbn ? this.book.isbn : item.isbn;
      var themes = []
        .concat(this.book.themes)
        .concat(item.categories)
        .filter((t, index, self) => self.indexOf(t) === index && t != null);
      this.book.themes = themes;
      this.book.exerpt = this.book.exerpt ? this.book.exerpt : item.description;
    },
    hasFilesFn(files) {
      var hasFiles = false;
      for (var ext in files) {
        if (files[ext] != null) {
          hasFiles = true;
          break;
        }
      }
      return hasFiles;
    },
    parseDate(date) {
      if (!date) {
        return null; //this.$moment.utc().format("YYYY MMMM");
      }

      var f = this.$moment.utc(date).format("YYYY MMM");

      return f;
    },
    searchMetadata() {
      this.$store
        .dispatch("library/searchBookWithExternalAPI", {
          bookTitle: this.book.title,
        })
        .then((items) => {
          this.bookDataSearch = items;
        })
        .catch((e) => {
          this.$store.commit("notification/" + types.SET_SNACKBAR, {
            message: this.$t("component.bookForm.message.noMetadataFound", {
              bookTitle: this.book.title,
            }),
          });
        });
    },
  },
  created() {
    // this.$store.dispatch("library/loadThemes", {});
    // this.$store.dispatch("utils/loadKeywords", {});
  },
};
</script>