<template>
  <v-sheet outlined class="px-6 py-2 rounded-lg">
    <v-subheader v-if="!!title" class="v-sheet-subheader">
      {{ title }}
    </v-subheader>
    <slot></slot>
  </v-sheet>
</template>
<script>
export default {
  props: { title: { type: String, required: false, default: null } },
};
</script>
<style scoped>
.v-subheader.v-sheet-subheader {
  margin-top: -2rem;
  /* margin-left: -1rem; */
  background-color: white;
  max-width: fit-content;
  margin-bottom: 1rem;
}
</style>
