import store from '../store'
import * as types from '../store/mutation-types'

export default {
    props: ['parameter', 'config'],
    methods: {
        close() {
            store.commit('notification/' + types.HIDE_DIALOG, this.config.id)
        }
    }
}