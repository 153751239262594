<template>
  <v-overlay :value="isLoading">
    <intersecting-circles-spinner
      :animation-duration="1200"
      :size="70"
      color="#ff1d5e"
    />
  </v-overlay>
</template>
<script>
import { IntersectingCirclesSpinner } from "epic-spinners";
export default {
  components: { IntersectingCirclesSpinner },
  data() {
    return {
      refCount: 0,
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters["isLoading"];
    },
  },
  methods: {
    setLoading(isLoading) {
      if (isLoading) {
        this.refCount++;
      } else if (this.refCount > 0) {
        this.refCount--;
      }
    },
  },
};
</script>