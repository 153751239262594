<template>
  <sheet :title="$t('component.bookForm.sections.authors')" class="mt-6 pt-6">
    <v-row>
      <v-col cols="5">
        <v-combobox
          ref="authorInput"
          v-model="temp"
          :label="$t('component.bookForm.fields.author.title')"
          dense
          :items="authorsList"
          item-text="name"
          item-value="publicId"
          :search-input.sync="authorSearch"
          outlined
          clearable
          append-outer-icon="mdi-plus"
          persistent-placeholder
          @change="addAuthor"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{ authorSearch }}</strong
                  >". Press <kbd>enter</kbd> to create a new one
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="5" offset-md="2">
        <v-list>
          <v-list-item dense v-for="(author, index) in authors" :key="index">
            <v-list-item-action>
              <v-btn icon>
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-title>
              <v-chip v-if="author.id == null" color="success" small>
                Created
              </v-chip>
              {{ author.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </sheet>
</template>
<script>
import Sheet from "../Shared/Sheet.vue";
export default {
  props: { authors: { type: Array, required: false, default: () => [] } },
  components: { Sheet },
  data() {
    return { temp: null, authorSearch: null };
  },
  computed: {
    authorsList() {
      var temp = this.$store.getters["author/authors"];
      temp = temp.filter((x) =>
        x["publicId"] == null
          ? true
          : !this.authors.some((y) => y.publicId == x.publicId)
      );
      return temp;
    },
  },
  watch: {},
  methods: {
    addAuthor(author) {
      if (typeof author === "string") {
        author = { name: author, publicId: null, bio: null, id: null };
      } else if (typeof author !== "object") {
        throw new Error("Author is not an object");
      }
      this.authors.push(author);
      this.$refs["authorInput"].reset();
      this.authorSearch = null;
    },
  },
  created() {},
};
</script>