var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sheet',{staticClass:"mt-6 pt-6"},[_c('v-form',{model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-text-field',{attrs:{"label":_vm.$t('component.bookForm.fields.title.label'),"required":"","dense":"","hide-details":"","rules":[
            function (v) { return !!v || _vm.$t('component.bookForm.fields.title.errors.required'); } ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-menu',{attrs:{"right":"","bottom":"","offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!_vm.book.title || !_vm.book.title.trim()},on:{"click":_vm.searchMetadata}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-book-search-outline")])],1)]}}])},[_c('v-list',[_vm._l((_vm.bookDataSearch),function(item,index){return [_c('v-list-item',{key:item.id,attrs:{"three-line":""},on:{"click":function($event){return _vm.fillBookInformations(item)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.title)+" ("+_vm._s(item.publishedDate)+")")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.authors)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.description)+" ")])],1)],1),(index < _vm.bookDataSearch.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2)],1)]},proxy:true}]),model:{value:(_vm.book.title),callback:function ($$v) {_vm.$set(_vm.book, "title", $$v)},expression:"book.title"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","label":_vm.$t('component.bookForm.fields.isbn.title'),"hint":_vm.$t('component.bookForm.fields.isbn.hint')},model:{value:(_vm.book.isbn),callback:function ($$v) {_vm.$set(_vm.book, "isbn", $$v)},expression:"book.isbn"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"label":_vm.$t('component.bookForm.fields.edition.title'),"type":"number","dense":"","min":"0","hide-details":"","rules":[
            function (v) { return !!!v ||
              !Number.isNaN(v) ||
              _vm.$t('component.bookForm.fields.edition.errors.notANumber'); },
            function (v) { return !!!v ||
              Number(v) > 0 ||
              _vm.$t('component.bookForm.fields.edition.errors.underOne'); } ]},model:{value:(_vm.book.edition),callback:function ($$v) {_vm.$set(_vm.book, "edition", $$v)},expression:"book.edition"}})],1),_c('v-col',{attrs:{"sm":"12"}},[_c('v-textarea',{attrs:{"label":_vm.$t('component.bookForm.fields.exerpt.title'),"validate-on-blur":"","dense":"","rows":"3","rules":[
            function (v) { return v == null ||
              v.length == 0 ||
              v.length > 3 ||
              _vm.$t('component.bookForm.fields.exerpt.errors.length'); } ],"hide-details":""},model:{value:(_vm.book.exerpt),callback:function ($$v) {_vm.$set(_vm.book, "exerpt", $$v)},expression:"book.exerpt"}})],1),_c('v-col',{attrs:{"md":"5"}}),(_vm.isEdit)?_c('v-checkbox',{attrs:{"label":_vm.$t('component.bookForm.fields.isActive.title')},model:{value:(_vm.book.isActive),callback:function ($$v) {_vm.$set(_vm.book, "isActive", $$v)},expression:"book.isActive"}}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"label":_vm.$t('component.bookForm.fields.themes.title'),"items":_vm.themes,"item-value":"id","item-text":"name","delimiters":[';'],"multiple":"","chips":"","dense":"","deletable-chips":"","small-chips":""},model:{value:(_vm.book.themes),callback:function ($$v) {_vm.$set(_vm.book, "themes", $$v)},expression:"book.themes"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-combobox',{attrs:{"label":_vm.$t('component.bookForm.fields.tags.title'),"items":_vm.keywords,"multiple":"","delimiters":[';'],"chips":"","dense":"","deletable-chips":"","small-chips":""},model:{value:(_vm.book.keywords),callback:function ($$v) {_vm.$set(_vm.book, "keywords", $$v)},expression:"book.keywords"}})],1),_c('v-col',{attrs:{"md":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"md":"12"}},[_c('p',{staticClass:"display-1 text-center"},[_vm._v(" "+_vm._s(_vm.$t("component.bookForm.fields.files.title"))+" ")])]),_vm._l((_vm.fileExtensions),function(t){return _c('v-col',{key:t,attrs:{"md":"4"}},[_c('v-file-input',{ref:t,refInFor:true,attrs:{"accept":("." + t),"label":t.toUpperCase(),"clearable":true,"truncate-length":"50","outlined":"","counter":"","prepend-icon":"","rules":[
                    function () { return _vm.hasFiles ||
                      _vm.$t('component.bookForm.fields.files.errors.length'); } ],"validate-on-blur":""},model:{value:(_vm.book.files[t]),callback:function ($$v) {_vm.$set(_vm.book.files, t, $$v)},expression:"book.files[t]"}})],1)})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }